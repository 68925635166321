import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from '@redux-saga/core'
import flashCardReducer from './flashCardReducer'
import watcherFlashCard from '../sagas/rootSaga'

const sagaMiddleware = createSagaMiddleware();
// const middlewares = []
// middlewares.push(sagaMiddleware)

// const composeEnhancers = process.env.NODE_ENV !== "production" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose

const store = createStore(flashCardReducer, applyMiddleware(sagaMiddleware))
sagaMiddleware.run(watcherFlashCard)
export default store