import { put, takeLatest } from 'redux-saga/effects'
import {
  GET_CN_FLASHCARDS
} from '../actions/flashCardAction'
import API from './../requests/API'

function* handleGetCnFlashCards(payload) {
  try {
    const response = yield API.getCnFlashCards(payload.cardName);
    if (response.status !== 200) {
      throw new Error('Unable to get card information')
    } else {
      yield put({type: GET_CN_FLASHCARDS, flashCards: response.data})
    }
  } catch (error) {
    console.log(error)
  }
}

function* watcherFlashCard() {
  yield takeLatest(GET_CN_FLASHCARDS, handleGetCnFlashCards)
}

export default watcherFlashCard;