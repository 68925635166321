import {
    GET_CN_FLASHCARDS
} from '../actions/flashCardAction'

const initialState = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false,
    flashCards: [],
}

const flashCardReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'set':
        return { ...state, ...action }
      case GET_CN_FLASHCARDS:
        return { ...state, flashCards: action.flashCards }
      default:
        return state
    }
  }
  
  export default flashCardReducer