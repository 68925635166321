import axios from 'axios'
import getApiUri from './getApiUri'

const serverURL = getApiUri()

const instance = axios.create({
  baseURL: `${serverURL}`,
  responseType: 'json',
})

export default {
  getCnFlashCards: (cardName) =>
  instance({
    method: 'GET',
    url: `/cn-flashcards?cards=${cardName}`,
  }),

  postFeedback: (fullnameInput, emailInput, feedbackInput) =>
  instance({
    method: 'POST',
    url: `/send-feedback`,
    data: {
      name: fullnameInput,
      email: emailInput,
      subject: 'Chinese Flash Card Feedback',
      message: feedbackInput
    }
  }),
}
